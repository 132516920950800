<template>
  <div ref="evaluationManage" class="evaluationManage">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="85px"
      >
        <el-form-item label="渠道：" class="code-time-range">
          <el-select
            v-model="form.channelNos"
            multiple
            collapse-tags
            clearable
            placeholder="请选择渠道"
          >
            <el-option
              v-for="item in channelList"
              :key="item.channelNo"
              :label="item.channelName"
              :value="item.channelNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评价时间起：">
          <el-date-picker
            v-model="form.createTimeStart"
            value-format="yyyy-MM-dd 00:00:00"
            type="date"
            placeholder="选择评价时间起"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="评价时间止：">
          <el-date-picker
            v-model="form.createTimeEnd"
            value-format="yyyy-MM-dd 23:59:59"
            type="date"
            placeholder="选择评价时间止"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="确认时间起：">
          <el-date-picker
            v-model="form.verificationTimeStart"
            value-format="yyyy-MM-dd 00:00:00"
            type="date"
            placeholder="选择确认时间起"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="确认时间止：">
          <el-date-picker
            v-model="form.verificationTimeEnd"
            value-format="yyyy-MM-dd 23:59:59"
            type="date"
            placeholder="选择确认时间止"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="线路：">
          <el-select
            v-model="form.lines"
            multiple
            collapse-tags
            clearable
            filterable
            placeholder="请选择线路"
          >
            <el-option
              v-for="item in routeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发车时间起：">
          <el-date-picker
            v-model="form.departureDateTimeStart"
            value-format="yyyy-MM-dd 00:00:00"
            type="date"
            placeholder="选择发车时间起"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间止：">
          <el-date-picker
            v-model="form.departureDateTimeEnd"
            value-format="yyyy-MM-dd 23:59:59"
            type="date"
            placeholder="选择发车时间止"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="司机：">
          <el-select
            v-model="form.driverId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入司机"
            clearable
            :remote-method="queryDriver"
            @clear="form.driverId = null"
          >
            <el-option
              v-for="item in driverList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-select
            v-model="form.licensePlateNumber"
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="请输入车牌号"
            :remote-method="queryCph"
            @clear="form.licensePlateNumber = null"
          >
            <el-option
              v-for="item in cphList"
              :key="item.licensePlateNumber"
              :label="item.licensePlateNumber"
              :value="item.licensePlateNumber"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源：">
          <el-select
            v-model="form.sources"
            clearable
            multiple
            placeholder="请选择来源"
          >
            <el-option
              v-for="item in sourcesList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input
            v-model.number="form.evaluatorPhone"
            clearable
            placeholder="请输入手机号码"
            @clear="form.evaluatorPhone = null"
          ></el-input>
        </el-form-item>
        <el-form-item label="评价等级：">
          <el-select
            v-model="form.grades"
            clearable
            multiple
            placeholder="请选择评价等级"
          >
            <el-option
              v-for="item in gradesList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="确认状态：">
          <el-select
            v-model="form.verificationStatuses"
            clearable
            multiple
            placeholder="请选择确认状态"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
          <el-button
            v-if="listFind('新增')"
            type="primary"
            size="small"
            @click="addFun"
            >新增评价</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :table-height="TableHeight"
      :col-class-type="true"
      :operation="true"
      :emptyText="emptyText"
      operation-width="150"
    >
      <template slot-scope="{ scopeRow }">
        <div>
          <el-button
            v-if="listFind('查看')"
            type="text"
            size="small"
            sort="primary"
            @click="referFun(scopeRow)"
            >查看</el-button
          >
          <el-button
            v-if="scopeRow.verificationStatus == 0 && listFind('确认')"
            type="text"
            size="small"
            sort="primary"
            @click="confirmFun(scopeRow)"
            >确认评价</el-button
          >
        </div>
      </template>
    </Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
    <charterPopup
      v-if="show1"
      width="750"
      header-text="新增评价"
      max-height="700px"
      @closePopupFun="show1 = false"
      @confirmFun="confirmBtn1"
    >
      <div class="main">
        <header>评价信息</header>
        <el-form
          ref="addForm"
          :model="ruleForm"
          :inline="true"
          label-position="right"
          label-width="95px"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="评价人：">
            <el-input
              v-model="ruleForm.evaluatorName"
              disabled
              placeholder="请输入评价人"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码：">
            <el-input
              v-model.number="ruleForm.evaluatorPhone"
              disabled
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="评价时间：">
            <el-date-picker
              v-model="ruleForm.evalationTime"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              disabled
              placeholder="选择评价时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="评价等级：">
            <el-select
              v-model="ruleForm.grade"
              clearable
              placeholder="请选择评价等级"
            >
              <el-option
                v-for="item in gradesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="评价留言：">
            <el-input
              v-model="ruleForm.message"
              type="textarea"
              :rows="3"
              placeholder="请输入评价留言"
            ></el-input>
          </el-form-item>
        </el-form>
        <header>班次信息</header>
        <el-form
          ref="form1"
          :model="ruleForm"
          :inline="true"
          label-position="right"
          label-width="95px"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="出发地：" prop="startStation">
            <el-select
              v-model="ruleForm.startStation"
              clearable
              placeholder="请选择出发地"
            >
              <el-option
                v-for="item in startList"
                :key="item.stationName"
                :label="item.stationName"
                :value="item.stationName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="到达地：" prop="endStation">
            <el-select
              v-model="ruleForm.endStation"
              clearable
              placeholder="请选择到达地"
            >
              <el-option
                v-for="item in endList"
                :key="item.stationName"
                :label="item.stationName"
                :value="item.stationName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出行时间：" prop="departureDate">
            <el-date-picker
              v-model="ruleForm.departureDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择出行时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="班次时间：" prop="lineId">
            <el-select
              v-model="ruleForm.shiftId"
              clearable
              placeholder="选择班次时间"
              @change="confirmShift"
              @focus="selectShift"
            >
              <el-option
                v-for="item in shiftList"
                :key="item.shiftIntercityId"
                :label="item.departureTime"
                :value="item.shiftIntercityId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="司机：" prop="driverName">
            <el-select
              v-model="ruleForm.driverId"
              placeholder="请选择司机"
              @change="selectDriver"
              @focus="selectOptions"
            >
              <el-option
                v-for="item in optionList"
                :key="item.driverId"
                :label="item.driverName"
                :value="item.driverId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号：" prop="licensePlateNumber">
            <el-select
              v-model="ruleForm.licensePlateNumber"
              placeholder="请选择车牌号"
              @focus="selectOptions"
              @change="selectCph"
            >
              <el-option
                v-for="item in optionList"
                :key="item.licensePlateNumber"
                :label="item.licensePlateNumber"
                :value="item.licensePlateNumber"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
    <charterPopup
      v-if="show2"
      width="750"
      :header-text="headerText"
      max-height="700px"
      @closePopupFun="show2 = false"
      @confirmFun="confirmBtn2"
    >
      <div class="main">
        <header>评价信息</header>
        <el-form
          :model="ruleForm2"
          :inline="true"
          label-position="right"
          label-width="95px"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="评价人：">
            <el-input v-model="ruleForm2.evaluatorName" disabled />
          </el-form-item>
          <el-form-item label="手机号码：">
            <el-input v-model.number="ruleForm2.evaluatorPhone" disabled />
          </el-form-item>
          <el-form-item label="评价时间：">
            <el-date-picker
              v-model="ruleForm2.createTime"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              disabled
            />
          </el-form-item>
          <el-form-item label="评价等级：">
            <el-select
              v-model="ruleForm2.grade"
              disabled
              placeholder="请选择评价等级"
            >
              <el-option
                v-for="item in gradesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="ruleForm2.label"
            label="评价标签："
            class="label"
            style="margin-right: 0"
          >
            <el-tag v-for="(item, index) in ruleForm2.label" :key="index">{{
              item
            }}</el-tag>
          </el-form-item>
          <el-form-item label="评价留言：">
            <el-input
              v-model="ruleForm2.message"
              type="textarea"
              :rows="3"
              disabled
            />
          </el-form-item>
        </el-form>
        <header>班次信息</header>
        <el-form
          :model="ruleForm2"
          :inline="true"
          label-position="right"
          label-width="95px"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="线路：">
            <el-input v-model="ruleForm2.lineName" disabled />
          </el-form-item>
          <el-form-item label="发车时间：">
            <el-date-picker
              v-model="ruleForm2.departureDateTime"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              disabled
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="司机：">
            <el-input v-model="ruleForm2.driverName" disabled></el-input>
          </el-form-item>
          <el-form-item label="车牌号：">
            <el-input
              v-model="ruleForm2.licensePlateNumber"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <header>评价确认</header>
        <el-form
          ref="form2"
          :model="ruleForm2"
          :inline="true"
          label-position="right"
          label-width="95px"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="确认人：" prop="people">
            <el-input v-model="ruleForm2.verificationUser" disabled />
          </el-form-item>
          <el-form-item label="确认结果：">
            <el-select
              v-model="ruleForm2.verificationResults"
              clearable
              :disabled="headerText == '查看评价' ? true : false"
              placeholder="请选择确认结果"
            >
              <el-option
                v-for="item in resultList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事件记录：">
            <el-input
              v-model="ruleForm2.verificationRemark"
              type="textarea"
              :disabled="headerText == '查看评价' ? true : false"
              :rows="3"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  exportEvaluationAPI,
  getVehicleList,
  queryEvaluationPageAPI,
  getCurrentUserChannelInfoAPI,
  searchLineAPI,
  searchShiftTimeAPI,
  getAllLine,
  byShiftIntercityIdAPI,
  saveEvaluationPageAPI,
  getDriverList,
  updateEvaluationAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      emptyText: "暂无数据",
      form: {
        currentPage: 1,
        pageSize: 10,
        createTimeStart: null,
        createTimeEnd: null,
        verificationTimeStart: null,
        verificationTimeEnd: null,
        lines: [],
        channelNos: [],
        departureDateTimeStart: null,
        driverId: null,
        licensePlateNumber: null,
        sources: [],
        grades: [],
        verificationStatuses: [],
        evaluatorPhone: null,
        id: null,
      },
      sourcesList: [
        {
          label: "用户 ",
          value: 0,
        },
        {
          label: "客服",
          value: 1,
        },
      ],
      gradesList: [
        {
          label: "好评 ",
          value: 0,
        },
        {
          label: "中评",
          value: 1,
        },
        {
          label: "差评",
          value: 2,
        },
        {
          label: "投诉",
          value: 3,
        },
        {
          label: "违规",
          value: 4,
        },
      ],
      addGradesList: [
        {
          label: "投诉",
          value: 3,
        },
        {
          label: "违规",
          value: 4,
        },
      ],
      statusList: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "已确认",
        },
      ],
      resultList: [
        {
          value: 0,
          label: "未通过",
        },
        {
          value: 1,
          label: "通过",
        },
      ],
      channelList: [],
      routeList: [],
      cphList: [],
      driverList: [],
      exportForm: {},
      TableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: "评价来源",
          props: "source",
          SpecialJudgment: (res) => {
            return res == 0 ? "用户" : "客服";
          },
        },
        {
          title: "评价人",
          props: "evaluatorName",
        },
        {
          title: "手机号码",
          props: "evaluatorPhone",
          width: 150,
        },
        {
          title: "评价时间",
          props: "createTime",
          width: 200,
        },
        {
          title: "评价等级",
          props: "grade",
          SpecialJudgment: (res) => {
            return this.gradesList.find((item) => item.value == res)?.label;
          },
        },
        {
          title: "评价标签",
          props: "label",
          width: 300,
          SpecialJudgment: (res) => {
            let text = "";
            res.map((item) => {
              text += item + "、";
            });
            return text.substr(0, text.length - 1);
          },
        },
        {
          title: "评价留言",
          props: "message",
          width: 150,
        },
        {
          title: "渠道",
          props: "channelName",
        },
        {
          title: "线路",
          props: "lineName",
          width: 100,
        },
        {
          title: "司机",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
          width: 100,
        },
        {
          title: "订单号码",
          props: "orderId",
          width: 230,
        },
        {
          title: "确认状态",
          props: "verificationStatus",
          SpecialJudgment: (res) => {
            return res == 0 ? "待确认" : "已确认";
          },
        },
        {
          title: "确认人",
          props: "verificationUser",
        },
        {
          title: "确认结果",
          props: "verificationResults",
          SpecialJudgment: (res) => {
            if (res != null) return res === 0 ? "未通过" : "通过";
          },
        },
        {
          title: "事件记录",
          props: "verificationRemark",
        },
        {
          title: "确认时间",
          props: "verificationTime",
          width: 200,
        },
      ],
      total: 0,
      startList: [],
      endList: [],
      shiftList: [],
      optionList: [],
      show1: false,
      show2: false,
      headerText: "",
      ruleForm: {
        evaluatorName: "",
        evaluatorPhone: "",
        evalationTime: new Date(),
        grade: 3,
        gradeName: "",
        message: "",
        endStation: "",
        shiftId: "",
        startStation: "",
        departureDate: "",
        lineId: "",
        driverId: "",
        driverName: "",
        licensePlateNumber: "",
      },
      ruleForm2: {
        id: "",
        evaluatorName: "",
        evaluatorPhone: "",
        createTime: "",
        gradeName: "",
        message: "",
        lineName: "",
        departureDateTime: "",
        driverName: "",
        licensePlateNumber: "",
        verificationUser: "",
        verificationResults: "",
        verificationRemark: "",
      },
      rules: {
        startStation: {
          required: true,
          message: "请选择出发地",
          trigger: "blur",
        },
        endStation: {
          required: true,
          message: "请选择出发地",
          trigger: "blur",
        },
        departureDate: {
          required: true,
          message: "请选择出行时间",
          trigger: "blur",
        },
        lineId: {
          required: true,
          message: "请选择班次时间",
          trigger: "blur",
        },
        driverName: {
          required: true,
          message: "请选择司机",
          trigger: "blur",
        },
        licensePlateNumber: {
          required: true,
          message: "请选择车牌号",
          trigger: "blur",
        },
      },
      form1: false,
      form2: false,
      form3: false,
    };
  },
  mounted() {
    this.form.id = this.$route.query.id;
    if (this.form.id) this.form.id = parseInt(this.form.id);
    this.exportForm = this.deepClone(this.form);
    this.queryChannels();
    this.queryRoutes();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    queryFun() {
      this.form.currentPage = 1;
      if (!this.form.channelNos.length) {
        this.channelList.forEach((e) => {
          this.form.channelNos.push(e.channelNo);
        });
      }
      this.exportForm = this.deepClone(this.form);
      this.renderData();
    },
    exportFun() {
      if (!this.form.channelNos.length) {
        this.channelList.forEach((e) => {
          this.form.channelNos.push(e.channelNo);
        });
      }
      this.exportForm = this.deepClone(this.form);
      exportEvaluationAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "评价列表 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    addFun() {
      this.show1 = true;
      this.selectLine({ direction: 0 });
      this.ruleForm.evaluatorName = sessionStorage.getItem("realname");
      this.ruleForm.evaluatorPhone = sessionStorage.getItem("phone");
      this.ruleForm.gradeName = this.addGradesList.find(
        (item) => item.value == this.ruleForm.grade
      ).label;
    },
    selectShift() {
      if (!this.ruleForm.startStation) {
        this.$message.warning("请选择出发地");
      } else if (!this.ruleForm.endStation) {
        this.$message.warning("请选择到达地");
      } else if (!this.ruleForm.departureDate) {
        this.$message.warning("请选择出行时间");
      } else {
        this.shiftList = [];
        searchShiftTimeAPI({
          startStation: this.ruleForm.startStation,
          endStation: this.ruleForm.endStation,
          departureDate: this.ruleForm.departureDate,
          shiftBefore: 1,
        })
          .then((res) => {
            if (res.code == "SUCCESS") {
              this.shiftList = res.data;
            }
          })
          .catch((res) => {});
      }
    },
    confirmShift(val) {
      const obj = this.shiftList.find((item) => item.shiftIntercityId == val);
      this.ruleForm.lineId = obj.lineId;
      this.ruleForm.lineName = obj.lineName;
      const date = new Date(obj.departureDate + " " + obj.departureTime);
      this.ruleForm.departureDateTime = date.getTime();
      byShiftIntercityIdAPI({ shiftIntercityId: val }).then((res) => {
        if (res.code == "SUCCESS") {
          const data = res.data;
          this.optionList = data;
          this.ruleForm.licensePlateNumber = data[0].licensePlateNumber;
          this.ruleForm.driverId = data[0].driverId;
          this.ruleForm.driverName = data[0].driverName;
          this.ruleForm.orderId = res.data[0].id;
        }
      });
    },
    selectOptions() {
      if (!this.ruleForm.shiftId) {
        this.$message.warning("请先选择班次时间");
      }
    },
    selectDriver(val) {
      const obj = this.optionList.find((item) => (item.driverId = val));
      this.ruleForm.driverName = obj.driverName;
      this.ruleForm.licensePlateNumber = obj.licensePlateNumber;
    },
    selectCph(val) {
      const obj = this.optionList.find(
        (item) => (item.licensePlateNumber = val)
      );
      this.ruleForm.driverId = obj.driverId;
      this.ruleForm.driverName = obj.driverName;
    },
    referFun(item) {
      this.show2 = true;
      this.headerText = "查看评价";
      this.ruleForm2 = this.deepClone(item);
    },
    confirmFun(item) {
      this.show2 = true;
      this.headerText = "评价确认";
      this.ruleForm2 = this.deepClone(item);
      this.ruleForm2.verificationUser = sessionStorage.getItem("realname");
      this.ruleForm2.verificationResults = 0;
    },
    confirmBtn1() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          saveEvaluationPageAPI(this.ruleForm).then((res) => {
            if (res.code == "SUCCESS") {
              this.renderData();
              this.$message.success("新增评价成功");
              this.show1 = false;
              this.ruleForm = {
                evaluatorName: "",
                evaluatorPhone: "",
                evalationTime: new Date(),
                grade: 3,
                gradeName: "",
                message: "",
                endStation: "",
                shiftId: "",
                startStation: "",
                departureDate: "",
                lineId: "",
                driverId: "",
                driverName: "",
                licensePlateNumber: "",
              };
            }
          });
        }
      });
    },
    confirmBtn2() {
      if (this.headerText == "查看评价") {
        this.show2 = false;
      } else {
        const obj = {
          id: this.ruleForm2.id,
          verificationResults: this.ruleForm2.verificationResults,
          verificationRemark: this.ruleForm2.verificationRemark,
        };
        updateEvaluationAPI(obj).then((res) => {
          if (res.code == "SUCCESS") {
            this.renderData();
            this.$message.success("确认评价成功");
            this.show2 = false;
          }
        });
      }
    },
    renderData() {
      queryEvaluationPageAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    queryChannels() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data || [];
            if (res.data.length === 1) {
              this.form.channelNos.push(res.data[0].channelNo);
            } else if (!res.data.length) {
              this.emptyText = "暂无权限，请联系管理员";
              this.$message.error("暂无权限，请联系管理员");
              return;
            }
          }
          this.renderData();
        })
        .catch(() => {
          this.renderData();
        });
    },
    queryRoutes() {
      getAllLine().then((res) => {
        if (res.code == "SUCCESS") this.routeList = res.data;
      });
    },
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleList({
            licensePlateNumber: query,
            currentPage: 1,
            pageSize: 999,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.cphList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.cphList = [];
      }
    },
    queryDriver(query) {
      this.driverList = [];
      if (query !== "") {
        setTimeout(() => {
          const data = {
            currentPage: 1,
            pageSize: 10,
            userName: query,
          };
          getDriverList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.driverList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },
    selectLine(obj) {
      searchLineAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          obj.direction == 0
            ? (this.startList = res.data.list)
            : (this.endList = res.data.list);
          obj.direction == 0 && this.selectLine({ direction: 1 });
        }
      });
    },

    computeHeight() {
      const wholeHeight = this.$refs.evaluationManage.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
  },
};
</script>

<style scoped lang="scss">
.evaluationManage {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
  .main {
    padding: 30px;
    box-sizing: border-box;
    header {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
    }
    /deep/.el-form {
      .el-form-item {
        &:nth-child(1) {
          margin-right: 50px;
        }
        &:nth-child(3) {
          margin-right: 50px;
        }
        &:nth-child(5) {
          margin-right: 50px;
        }
      }
      .label {
        width: 100%;
        .el-tag {
          margin: 0 5px;
        }
      }
      .el-input__inner {
        width: 195px;
      }
      .el-textarea__inner {
        width: 260%;
      }
    }
  }
}
</style>
